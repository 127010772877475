/* SplitSectionLeftRight.css */

/* Contenedor general */
.split-section {
    width: 100%;
    height: auto;
    padding: 40px 5%;
    display: flex;
    align-items: center;
  }
  
  /* Imagen en la parte superior izquierda */
  .split-section .left-image {
    width: 50%;
    margin-bottom: 20px;
    border-radius: 12px;
  }
  
  /* Título y descripción */
  .split-section h4 {
    font-family: 'Codec Pro', sans-serif;
    font-weight: 800;
  }
  
  .split-section p {
    font-family: 'Arimo', sans-serif;
  }
  
  /* Botones */
  .split-section button {
    font-family: 'Codec Pro', sans-serif;
    font-weight: 800;
    border-radius: 24px;
    padding: 10px 20px;
    text-transform: none;
    background-color: #D8D8D8;
    color: #2E1A47;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra difuminada */
  }
  
  .split-section button:hover {
    background-color: #cfcfcf;
  }
  