/* TextSection.css */

.text-section {
    width: 100%;
    height: 60vh; /* Altura basada en el viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%; /* Padding horizontal para mantener el diseño contenido */
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .main-text {
    font-family: 'Codec Pro', sans-serif;
    font-weight: 800;
    font-size: 3vw; /* Tamaño responsivo basado en el ancho del viewport */
    line-height: 1.2;
    color: #ffffff; /* Texto blanco por defecto */
    margin: 0;
  }
  
  .highlight {
    color: #00b894; /* Verde para "Nova" */
  }
  
  .secondary-text {
    font-family: 'Codec Pro', sans-serif;
    font-weight: 400;
    font-size: 1.5vw; /* Tamaño responsivo basado en el ancho del viewport */
    line-height: 1.5;
    margin-top: 2%;
    color: #ffffff;
  }
  