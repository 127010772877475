/* HeaderSection.css */

.header-section {
    display: flex;
    flex-direction: column; /* Asegura que los elementos estén en columna */
    align-items: center; /* Centra horizontalmente todos los elementos */
    justify-content: center; /* Centra verticalmente todos los elementos */
    width: 100%;
    height: 100vh; /* Altura completa del viewport */
    background-size: cover;
    background-position: center;
    position: relative; /* Para posicionar elementos internos */
  }
  
  .content-container {
    text-align: center;
    width: 80%; /* Ancho máximo relativo para no ocupar todo el espacio */
    max-width: 90%;
  }
  
  .center-image {
    margin-bottom: 5%; /* Espaciado relativo debajo de la imagen central */
    cursor: pointer;
    animation: bounce 2s infinite; /* Animación opcional */
  }
  
  .center-image img {
    width: 40%; /* Tamaño relativo al ancho del viewport */
    height: auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: center; /* Centra los íconos horizontalmente */
    gap: 50%; /* Espacio relativo entre los íconos */
    position: absolute;
    bottom: 5%; /* Alinea los íconos en la parte inferior de la sección */
    left: 50%; /* Centra los íconos horizontalmente en la sección */
    transform: translateX(-50%); /* Compensa el desplazamiento del 50% */
  }
  